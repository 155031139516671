.slider-container-careers {
  padding-top: 100px;
  .image-container-careers {
    height: calc(100vh - 100px);
      align-items: center !important;
      display: flex;
    img {
      padding: 2.5rem;
      max-height: calc(100vh - 100px);
    }
  }
  .slider-text-container {
    max-width: 420px;
    @media (min-width:1660px) {
      max-width: 540px;
    }
    h1 {
      font-size: 5rem;
      text-transform: uppercase;
      color: #001b66;
      font-weight: 700;
      @media (min-width:1660px) {
        font-size: 6rem;
      }
      @media (max-width:1280px) {
        font-size: 5rem;
      }
      @media (max-width:768px) {
        font-size: 2rem;
      }
    }
  }
}
.join-header {
  font-size: 2.5rem;
  text-transform: uppercase;
  color: #001b66;
  font-weight: 700;
  padding-bottom: 1rem;
}
.slide-right {
  -webkit-animation: slideInRight 1s forwards;
  -moz-animation: slideInRight 1s forwards;
  animation: slideInRight 1s forwards;
}
.slide-right2 {
  -webkit-animation: slideInRight 1.2s forwards;
  -moz-animation: slideInRight 1.2s forwards;
  animation: slideInRight 1.2s forwards;
}
.slide-right3 {
  -webkit-animation: slideInRight 1.4s forwards;
  -moz-animation: slideInRight 1.4s forwards;
  animation: slideInRight 1.4s forwards;
}
.slide-right4 {
  -webkit-animation: slideInRight 1.6s forwards;
  -moz-animation: slideInRight 1.6s forwards;
  animation: slideInRight 1.6s forwards;
}
@-webkit-keyframes slideInRight {
  0% {
    transform: translateX(5000px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideInRight {
  0% {
    transform: translateX(5000px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInRight {
  0% {
    transform: translateX(5000px);
  }
  100% {
    transform: translateX(0);
  }
}
.mailForm {
  border: none;
}
