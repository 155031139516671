.footer {
  background:url('./img/footer-background.svg');
  background-repeat: no-repeat;
  background-size:cover;
  background-color: #001b66;
  background-position: center center;
  @media (max-width:768px) {
    .no-mobile {
      display: none !important;
    }
  }
  .makers {
    font-size: 0.75rem;
  }
  .contact-container {
  @media (max-width:768px) {
    font-size: 0.8rem;
  }
    .pin {
      max-width: 16px;
      margin-top: -10px;
      margin-right: 10px;
    }
    .phone {
      max-width: 16px;
      margin-right: 10px;
    }
    .fax {
      max-width: 20px;
      margin-right: 10px;
      margin-left: 30px;
    }
  }
  .footer-menu-container {
    display: flex;
    justify-content: center;
  }
  h2, h3, h4, h5, p {
    color:white;
  }
  p {
    font-weight: 300;
  }
  h5 {
    padding-bottom: 1.5rem;
  }
  .logo-footer {
    max-width: 192px;
  }
}
