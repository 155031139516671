@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  overflow-y: auto;
  button {
    color: white;
    background-color: #1089FF;
    font-weight: 500;
    padding: 0.5rem 2rem;
    border: 0;
    border-radius: 2rem;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: 0.25s ease-in-out;
    outline: 0;
    &:hover {
      background-color: #001B66;
      color: #76E2FF;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes new {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.main-div {
  animation: new .5s;
}
