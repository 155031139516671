.slider-container-air {
  height: calc(100vh - 100px);
  background-image: url('./img/air-header.jpg');
  margin-top: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  .slider-container-work-text {
    display: flex;
    flex-flow: column;
    justify-content: center;
    h1 {
      font-size: 4rem;
      color: white;
      text-align: center;
      padding-bottom: 2rem;
      font-weight: 700;
      filter: drop-shadow(2px 4px 6px #000);
      @media (max-width:768px) {
        font-size: 2.25rem;
      }
    }
    h2 {
      color: white;
      text-align: center;
      padding-bottom: 1rem;
      font-weight: 500;
      filter: drop-shadow(2px 4px 6px #000);
      @media (max-width:768px) {
        font-size: 1.25rem;
      }
    }
    button {
      color: white !important;
      background-color: rgba(16, 137, 255, 0.5) !important;
      border: 2px solid white !important;
      width: 50% !important;
      font-weight: 500;
      padding: 0.5rem 2rem;
      border-radius: 2rem;
      box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
      cursor: pointer;
      transition: 0.25s ease-in-out;
      outline: 0;
      margin: 2rem auto 0 auto;
      font-size: 1.25rem;
      &:hover {
        background-color: white !important;
        color: #1089FF !important;
      }
      @media (max-width:768px) {
        width: 66% !important;
      }
    }
  }
}
.slider-container {
  padding-top: 100px;
  .image-container {
    background-image: url('./img/home-slider.png');
    height: calc(100vh - 100px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
  }
  @media (max-width:768px) {
    .row {
      padding: 0;
      margin: 0;
    }
    .image-container {
      padding: 0;
    }
  }
 .slider-text-container {
    max-width: 400px;
    animation-name: go;
    animation-duration: 1s;
    @media (max-width:1280px) {
      padding-left: 15px;
    }
    @media (max-width:768px) {
      padding-top: 1rem;
    }
    h1 {
      font-size: 3rem;
      text-transform: uppercase;
      color: #001b66;
      font-weight: 700;
        @media (max-width:1280px) {
          font-size: 3rem;
        }
        @media (max-width:768px) {
          font-size: 2rem;
        }
    }
  }
  @keyframes go {
    0% {opacity: 0;}
    75% {opacity: 0;}
    100% {opacity: 1;}
  }
}
  .categories {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media (max-width:768px) {
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .picture-truck {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 50%;
      -ms-flex: 0 1 50%;
      flex: 0 1 50%;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
      background-image: url('./img/truck-slider.jpg');
      height: 640px;
      background-repeat: no-repeat;
      background-size: cover;
      @media (max-width:1024px) {
        height: 420px;
      }
    }
    .picture-cargo {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 50%;
      -ms-flex: 0 1 50%;
      flex: 0 1 50%;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
      background-image: url('./img/cargo-slider.jpg');
      height: 640px;
      background-repeat: no-repeat;
      background-size: cover;
      @media (max-width:1024px) {
        height: 420px;
      }
    }
    .picture-time {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 50%;
      -ms-flex: 0 1 50%;
      flex: 0 1 50%;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
      background-image: url('./img/time-slider.jpg');
      height: 640px;
      background-repeat: no-repeat;
      background-size: cover;
      @media (max-width:1024px) {
        height: 420px;
      }
    }
    .picture-airplane {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 50%;
      -ms-flex: 0 1 50%;
      flex: 0 1 50%;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
      background-image: url('./img/airplane-slider.jpg');
      height: 640px;
      background-repeat: no-repeat;
      background-size: cover;
      @media (max-width:1024px) {
        height: 420px;
      }
    }
    .content {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 50%;
      -ms-flex: 0 1 50%;
      flex: 0 1 50%;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding: 2rem;
      .content-container {
        max-width: 480px;
        text-align: center;
        h3 {
          color: #001b66;
          font-size: 2.5rem;
          font-weight: 700;
          padding-bottom: 2rem;
        }
        p {
          padding-bottom: 2rem;
        }
      }
    }
  }
  .services {
    a {
      width: inherit;
      text-align: center;
    }
    button {
      color: #1089FF !important;
      background-color: white !important;
      border: 2px solid #1089FF !important;
      width: 50% !important;
      font-weight: 500;
      padding: 0.5rem 2rem;
      border-radius: 2rem;
      box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
      cursor: pointer;
      transition: 0.25s ease-in-out;
      outline: 0;
      &:hover {
        background-color: #1089FF !important;
        color: white !important;
      }
      @media (max-width:768px) {
        width: 66% !important;
      }
    }
    .service-container {
      text-align: center;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: space-around;
      -webkit-align-content: space-between;
      -ms-flex-line-pack: justify;
      align-content: space-between;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      min-height: 256px;
    p {
      font-size: 1.75rem;
      color: #001b66;
      font-weight: 500;
      padding-top: 1.5rem;
    }
    .icon {
      max-width: 128px;
    }
    .icon-boat {
      max-width: 192px;
    }
  }
}
.slide-left {
  -webkit-animation: slideIn 1s forwards;
  -moz-animation: slideIn 1s forwards;
  animation: slideIn 1s forwards;
}
.slide-left2 {
  -webkit-animation: slideIn 1.2s forwards;
  -moz-animation: slideIn 1.2s forwards;
  animation: slideIn 1.2s forwards;
}
.slide-left3 {
  -webkit-animation: slideIn 1.4s forwards;
  -moz-animation: slideIn 1.4s forwards;
  animation: slideIn 1.4s forwards;
}
.slide-left4 {
  -webkit-animation: slideIn 1.6s forwards;
  -moz-animation: slideIn 1.6s forwards;
  animation: slideIn 1.6s forwards;
}
@-webkit-keyframes slideIn {
  0% {
    transform: translateX(-5000px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideIn {
  0% {
    transform: translateX(-5000px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideIn {
  0% {
    transform: translateX(-5000px);
  }
  100% {
    transform: translateX(0);
  }
}
@media (min-width:768px) {
  .mobile-only {
    display: none;
  }
}
@media (max-width:768px) {
  .icon {
    max-height: 192px;
  }
  .carousel-item {
    p {
      text-align: center;
      color: #001b66;
      font-size: 1.75rem;
      padding-top: 1.5rem;
      font-weight: 500;
    }
  }
  .carousel-caption {
    color: #001b66;
    font-size: 1.5rem;
    font-weight: 700;
  }
  .carousel-indicators {
    bottom: -40px;
    li {
     background-color: #001b66;
   }
  }
  .carousel-control-next-icon, .carousel-control-prev-icon {
    background-color: #001b66;
    border-radius: 100%;
    border: 5px solid #001b66;
    width: 40px;
    height: 40px;
    margin: 10px;
  }
  .desktop-only {
    display: none;
  }
}
ul {
    list-style: none;
}
