.brand-logo {
  height: 75px;
  transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.navbar {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,.1);
  transition: 0.25s ease-in-out;
  .container {

  }
}
.nav-link {
  font-size: 1.15rem;
  color: #21157e!important;
  font-weight: 400;
  text-transform: uppercase;
  transition: 0.25s ease-in-out;
  @media (min-width:992px) {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  &:hover {
    color: #1089ff !important;
  }
}
.nav-link.active {
  color: #1089ff !important;
  font-weight: 700;
}
@media (min-width:992px) {
  .scrolled {
     padding: 0rem 1rem !important;
     transition: 0.25s ease-in-out;
    .brand-logo {
      height: 50px;
      transition: 0.25s ease-in-out;
    }
    .nav-link {
      font-size: 1rem;

    }
  }
}
